/* src/Components/Logs.css */

.call-paper {
    padding: 16px;
    position: relative;
  }
  
  .json-display-small {
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 4px;
    overflow-x: auto;
    font-family: monospace;
    font-size: 12px;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .json-display {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
    font-family: monospace;
    font-size: 14px;
  }
  