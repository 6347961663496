/* src/Components/PromptEditor.css */

.prompt-editor-container {
    margin-bottom: 16px;
  }
  
  .prompt-editor-expanded {
    display: flex;
  }
  
  .markdown-preview {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 4px;
    overflow-y: auto;
    max-height: 600px;
    width: 100%;
  }
  
  .markdown-content {
    white-space: pre-wrap;
  }
  