/* src/Components/FunctionVersionPage.css */
.function-version-container {
  flex-grow: 1;
  padding: 16px; /* 2 units */
}

.back-link {
  display: inline-block;
  margin-bottom: 16px;
  text-decoration: none;
  color: #1976d2;
}

.back-link:hover {
  text-decoration: underline;
}

.sidebar {
  border-right: 1px solid #ccc;
}

.sidebar-content {
  height: calc(100vh - 80px); /* Adjust based on your header/footer */
  overflow: auto;
}

.main-content {
  padding: 16px;
}

.main-content-box {
  padding: 16px;
}

/* src/Components/FunctionVersionPage.css */

/* Existing styles */

.prompt-editor-box {
  margin-top: 16px;
}

.prompt-editor-container {
  margin-bottom: 16px;
}

.prompt-editor-expanded {
  display: flex;
  flex-direction: row;
}

.markdown-preview {
  width: 50%;
}

.markdown-content {
  overflow-y: auto;
  max-height: 400px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.eval-tests-container {
  margin-top: 16px;
}
